import React, { Component } from "react";
import PropTypes from "prop-types";

import GenericSection from "../components/sections/GenericSection";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import { API } from "../constants";

class UnsubscribeNewsletter extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = { email: "", isLoading: false };

  handleSubmit = (event) => {
    event.preventDefault();

    const { email } = this.state;

    this.setState({ isLoading: true });
    fetch(API + "/user/unsubscribe-from-newsletter/" + email, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .catch(() => this.setState({ isLoading: false }))
      .then((response) => {
        this.setState({ isLoading: false });
        if (response && response.error && response.reason) {
          if (
            response.reason ===
            `Die E-Mail Adresse ${email} war noch nicht im Newsletter eingetragen`
          ) {
            fetch(API + "/user/subscribe-to-newsletter", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                email: email,
                hasConsented: false,
              }),
            }).then(() => {
              alert("Sie wurden erfolgreich aus dem Newsletter ausgetragen");
              this.props.history.push("/");
            });
            return;
          }
          alert(response.reason);
          return;
        }
        alert("Sie wurden erfolgreich aus dem Newsletter ausgetragen");
        this.props.history.push("/");
      });
  };

  render() {
    return (
      <GenericSection>
        <div className="container-xs text-xs">
          <h1>Newsletter</h1>
          <p>Hier können Sie sich aus unserem Newsletter abmelden</p>

          <form onSubmit={this.handleSubmit}>
            <Input
              className="newsletter-unsubscribe-input"
              type="email"
              placeholder="Ihre E-Mail Adresse"
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
              style={{ borderColor: "#91D4F8" }}
              required
            />
            <Button
              color="secondary"
              type="submit"
              loading={this.state.isLoading}
            >
              vom Newsletter abmelden
            </Button>
          </form>
        </div>
      </GenericSection>
    );
  }
}

export default UnsubscribeNewsletter;
