import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Swiper from "react-id-swiper";

import "swiper/css/swiper.css";
import { API } from "../../constants";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Products extends React.Component {
  state = {
    products: [],
  };

  componentDidMount() {
    this.getProductData();
  }

  getProductData = () => {
    fetch(API + "/products", {
      method: "GET",
    })
      .then((response) => response.json())
      .catch((error) => alert(error))
      .then((response) => {
        if (response.error) {
          console.log(response.reason);
          return;
        }
        this.setState({ products: response });
      });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "products section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "products-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Produktempfehlungen",
      paragraph: "Empfohlen von uns für Sie, passend zur Online Gedenkfeier.",
    };

    const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
    const isDesktop = window.innerWidth >= 1024;
    const numerOfProductsPerView = isDesktop ? 3 : isTablet ? 2 : 1;

    return (
      <section {...props} className={outerClasses}>
        <div className="container" id="products">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content products-center-content"
            />
            {this.state.products.length > 0 && (
              <Swiper
                spaceBetween={15}
                slidesPerView={numerOfProductsPerView}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  clickable: true,
                }}
              >
                {this.state.products.map((product) => (
                  <a
                    href={
                      product.link.target +
                      "?utm_source=trauerfeier.online&utm_campaign=suggested_products"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="products__swiper-product"
                    key={product.id}
                  >
                    <h3 className="products__swiper-product_category">
                      {product.category}
                    </h3>
                    <div className="products__swiper-product_image-wrapper">
                      <img
                        src={product.image.url}
                        className="products__swiper-product_image"
                        alt={product.title}
                      />
                    </div>
                    <div className="products__swiper-product_title">
                      {product.title}
                    </div>
                    <div className="products__swiper-product_price">
                      ab {parseFloat(product.price).toFixed(2)} €
                    </div>
                    <Button className="products__swiper-product_link button-sm">
                      {product.link.title}
                    </Button>
                  </a>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>
    );
  }
}

Products.propTypes = propTypes;
Products.defaultProps = defaultProps;

export default Products;
