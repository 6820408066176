import React from "react";
import moment from "moment";
import "moment/locale/de";
import { PropTypes as pt } from "prop-types";
import stringToUrlFriendly from "../utils/stringToUrlFriendly";
import concatUrls from "../utils/concatUrls";

import GenericSection from "../components/sections/GenericSection";
import MetaTags from "../utils/MetaTags";
import Button from "../components/elements/Button";
import { API } from "../constants";

const propTypes = {
  history: pt.object,
  location: pt.object,
  match: pt.object,
};

const defaultProps = {};

class Event extends React.Component {
  state = {
    event: null,
    isLoading: true,
  };

  componentDidMount() {
    const {
      match: {
        params: { id: eventID },
      },
    } = this.props;
    this.getEventData(eventID);

    moment().locale("de");
  }

  getEventData = (eventID) => {
    const { history } = this.props;

    this.setState({ isLoading: true });
    fetch(API + "/events/" + eventID, {
      method: "GET",
    })
      .then((response) => response.json())
      .catch((error) => alert(error))
      .then((response) => {
        if (response.error) {
          console.log(response.reason);
          this.setState({ isLoading: false });
          return;
        }

        history.replace(
          "/" +
            concatUrls("events", eventID, stringToUrlFriendly(response.title))
        );
        this.setState({ event: response, isLoading: false });
      });
  };

  render() {
    const { event, isLoading } = this.state;

    if (!event) {
      if (!isLoading) {
        this.props.history.push("/");
      }
      return <></>;
    }

    const eventIsInFuture = moment().isBefore(event.startDate);
    const eventIsStartingSoon =
      eventIsInFuture &&
      moment().diff(event.startDate, "minutes") <= 0 && // diff is negative
      moment().diff(event.startDate, "minutes") > -60; // max. 60 minutes away
    const eventStartsInMinutes = moment().diff(event.startDate, "minutes") * -1;
    const eventEndsInMinutes = moment().diff(event.endDate, "minutes") * -1;
    const eventStartsToday =
      eventIsInFuture && moment().isSame(event.startDate, "day");
    const eventIsFarAway =
      eventIsInFuture && !eventIsStartingSoon && !eventStartsToday;
    const eventHasStarted = moment().isAfter(event.startDate);
    const eventHasEndedBySchedule = moment().isAfter(event.endDate);
    const eventHasEndedForSure =
      // we only know the event has definitely ended when there's an archive link
      // otherwise, the event might still be running even after the planned end date
      eventHasEndedBySchedule && event.streamArchiveLink;
    const eventWasToday =
      moment().isAfter(event.endDate) && moment().isSame(event.endDate, "day");
    const eventIsOrWasToday =
      moment().isSame(event.startDate, "day") ||
      moment().isSame(event.endDate, "day");
    const eventIsRunning = eventHasStarted && !eventHasEndedBySchedule;

    return (
      <>
        <MetaTags title={event.title} description={event.description} />

        <GenericSection>
          <div className="container-xs text-xs">
            <h1 className="mb-0">{event.mourned?.name || event.title}</h1>
            <h3>{event.description}</h3>
            <div className="text-xxs" style={{ marginBottom: 10 }}>
              Organisiert durch <i>{event.organizer}</i>
            </div>
            {eventIsFarAway && (
              <>
                Die Feier startet am
                {moment(event.startDate).format(
                  " dddd[, dem] DD. MMM yyyy [um] HH:mm [Uhr] (Z)"
                )}
              </>
            )}
            {eventStartsToday && !eventIsStartingSoon && (
              <>
                Die Feier startet heute um
                <b>{moment(event.startDate).format(" HH:mm [Uhr] (Z)")}</b>
              </>
            )}
            {eventIsStartingSoon && (
              <>
                Die Feier{" "}
                <b>
                  startet{" "}
                  {eventStartsInMinutes > 2 ? (
                    <>in {eventStartsInMinutes} Minuten</>
                  ) : (
                    "gleich"
                  )}
                </b>
                :{moment(event.startDate).format(" HH:mm [Uhr] (Z)")}
              </>
            )}
            {eventIsRunning && (
              <>
                Die Feier <b>hat bereits angefangen</b>, aber läuft planmäßig{" "}
                <b>noch {eventEndsInMinutes} Minuten</b>, bis
                {moment(event.endDate).format(" HH:mm [Uhr] (Z)")}.
              </>
            )}
            {eventHasEndedBySchedule && (
              <>
                {eventWasToday ? (
                  <>
                    Die Feier ist <b>planmäßig</b> bereits{" "}
                    <b>
                      seit
                      {moment(event.endDate).format(" HH:mm [Uhr] (Z)")} vorbei
                    </b>
                    .
                    <br />
                  </>
                ) : (
                  <>
                    Die Feier <b>ist bereits vorbei</b>. Sie fand statt am
                    {moment(event.startDate).format(
                      " dddd[, dem] DD. MMM yyyy [um] HH:mm [Uhr] (Z)"
                    )}
                    <br />
                    <br />
                  </>
                )}
                {eventHasEndedForSure ? (
                  <>
                    Du kannst dir die Aufzeichnung dieser Feier im Archiv
                    ansehen:
                  </>
                ) : eventWasToday ? (
                  <>
                    Möglicherweise läuft sie aber trotzdem noch, denn der
                    Organisator hat noch keine Aufzeichnung bereitgestellt.
                  </>
                ) : (
                  <>
                    Der Organisator hat leider <b>noch keine Aufzeichnung</b>{" "}
                    bereitgestellt. Bitte komm später noch mal vorbei.
                  </>
                )}
              </>
            )}
            {((event.sessionEmbedLink &&
              (eventIsInFuture || eventIsOrWasToday)) ||
              (event.streamArchiveLink && eventHasEndedForSure)) && (
              <>
                <iframe
                  width="560"
                  height="315"
                  src={
                    eventHasEndedForSure
                      ? event.streamArchiveLink
                      : event.sessionEmbedLink
                  }
                  title={event.description}
                  frameBorder="0"
                  allow="fullscreen;"
                  allowFullScreen
                  style={{ maxWidth: "100%", marginTop: 20 }}
                ></iframe>
                <div className="text-xxs">
                  Für Vollbild doppelt auf den Live-Stream klicken, oder{" "}
                  <code>F</code> drücken
                </div>
              </>
            )}
            {!event.sessionEmbedLink && event.sessionLink && eventIsOrWasToday && (
              <div>
                <Button
                  tag="a"
                  color="primary"
                  href={event.sessionLink}
                  style={{ marginTop: 20 }}
                >
                  Jetzt teilnehmen
                </Button>
              </div>
            )}
            {eventIsInFuture &&
              !(event.sessionLink || event.sessionEmbedLink) && (
                <div>
                  Der Organisator dieser Feier hat noch keinen Teilnahmelink
                  hinzugefügt. Bitte kommen Sie später wieder.
                </div>
              )}
          </div>
        </GenericSection>
      </>
    );
  }
}

Event.propTypes = propTypes;
Event.defaultProps = defaultProps;

export default Event;
