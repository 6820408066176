import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
// import FooterSocial from './partials/FooterSocial';
import { Link } from "react-router-dom";
import Cta from "../../components/sections/Cta";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

class Footer extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { className, topOuterDivider, topDivider, ...props } = this.props;

    const classes = classNames(
      "site-footer invert-color",
      topOuterDivider && "has-top-divider",
      className
    );

    return (
      <footer {...props} className={classes}>
        <div className="container">
          <Cta hasBgColor invertColor topDivider bottomDivider split />
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo
                    appearance="light"
                    className="mb-28"
                    style={{ marginTop: -16 }}
                  />
                  <div className="footer-copyright">
                    &copy; {moment().year()} CONVELA GmbH
                    <br />
                    all rights reserved
                  </div>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Unternehmen</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <Link to="/imprint">Impressum</Link>
                    </li>
                    <li>
                      <Link to="/terms">AGB</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Datenschutz</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Technisches</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a
                        href="https://support.zoom.us/hc/de/articles/201362193-Wie-nehme-ich-an-einem-Meeting-teil"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Wie nehme ich an einem Meeting teil?
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://zoom.us/test"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Wie teste ich meine Technik?
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Formulare</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <Link to="/forms/choose-a-moderator">
                        Auswahl des Moderators
                      </Link>
                    </li>
                    <li>
                      <Link to="/forms/about-the-ceremony">
                        Infos zur Gedenkfeier
                      </Link>
                    </li>
                    <li>
                      <Link to="/forms/guest-list">Gästeliste</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Weitere Links</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a
                        href="//aktionlichtpunkt.de?utm_source=trauerfeier.online&utm_medium=footer"
                        target="_blank"
                        rel="noopener"
                      >
                        Aktion Lichtpunkt →
                      </a>
                    </li>
                    <li>
                      <a
                        href="//vorsorgeplaner.online?utm_source=trauerfeier.online&utm_medium=footer"
                        target="_blank"
                        rel="noopener"
                      >
                        Online vorsorgen →
                      </a>
                    </li>
                    <li>
                      <a
                        href="//abschiedsplaner.online?utm_source=trauerfeier.online&utm_medium=footer"
                        target="_blank"
                        rel="noopener"
                      >
                        Abschiede planen →
                      </a>
                    </li>
                    <li>
                      <a
                        href="//convela.academy?utm_source=trauerfeier.online&utm_medium=footer"
                        target="_blank"
                        rel="noopener"
                      >
                        Branchen-Ausbildungen →
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <FooterNav
                isModalOpen={this.state.isModalOpen}
                openModal={() => this.setState({ isModalOpen: true })}
                closeModal={() => this.setState({ isModalOpen: false })}
              />
              {/* <FooterSocial /> */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
