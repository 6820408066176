import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
// import SideBanner from "../components/elements/SideBanner";

const Layout = ({ children }) => (
  <>
    <Header navPosition="right" hideSignin />

    <main className="site-content">{children}</main>
{/*
    {!children.props.location.pathname.includes("bewerten") && (
      <SideBanner
        forceExpanded={children.props.location.search.includes("register")}
        history={children.props.history}
      />
    )}
*/}
    <Footer className="illustration-section-04" />
  </>
);

export default Layout;
