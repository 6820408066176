import React from "react";
import classNames from "classnames";
import { SectionProps } from "../utils/SectionProps";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Input from "../components/elements/Input";
import Button from "../components/elements/Button";
import { API } from "../constants";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class KGYourChild extends React.Component {
  state = {
    userEmail: null,
    userID: null,
    isSignupShown: false,
    hasAddedChild: false,
  };

  componentDidMount() {
    const userID = new URLSearchParams(window.location.search).get("uid");
    if (userID && Number(userID)) {
      this.setState({ userID: Number(userID) });
    }
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "signin section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "signin-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: this.state.hasAddedChild
        ? "Wir gedenken gemeinsam deinem Kind."
        : "Wir wollen Deinem Kind gedenken.",
    };

    const startLoading = () => this.setState({ isLoading: true });
    const stopLoading = () => this.setState({ isLoading: false });

    const checkEmail = (event) => {
      event.preventDefault();

      startLoading();
      fetch(API + "/users/with?email=" + this.state.userEmail, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 404) {
            return this.setState({ isSignupShown: true });
          }
          return response.json();
        })
        .catch(stopLoading)
        .then((response) => {
          stopLoading();
          if (!response) return;
          if (response.error) {
            alert(response.reason);
            return;
          }
          if (response.id) {
            this.setState({ userID: response.id });
          }
        });
    };

    const signupParticipateAddChild = (event) => {
      event.preventDefault();

      const password = Math.random().toString(36).substring(8);
      startLoading();
      fetch(API + "/user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: {
            first: this.state.userFirstName,
            last: this.state.userLastName,
          },
          email: this.state.userEmail,
          password: password,
        }),
      })
        .then((response) => response.json())
        .catch(stopLoading)
        .then((response) => {
          if (response.error) {
            alert(response.reason);
            stopLoading();
            return;
          }
          if (response.id) {
            this.setState({ userID: response.id, isSignupShown: false });
          }

          fetch(API + "/user/login", {
            method: "POST",
            headers: {
              Authorization:
                "Basic " + btoa(this.state.userEmail + ":" + password),
            },
          })
            .catch(stopLoading)
            .then((response) => response.json())
            .then((response) => {
              if (response.error) {
                alert(response.reason);
                stopLoading();
                return;
              }

              fetch(API + "/events/6/participate", {
                method: "POST",
                headers: { Authorization: "Bearer " + response.value },
              })
                .catch(stopLoading)
                .then((response) => {
                  if (response.ok) {
                    return;
                  } else {
                    return response.json();
                  }
                })
                .then((response) => {
                  stopLoading();
                  if (response && response.error) {
                    alert(response.reason);
                    return;
                  }
                });
            });
        });
    };

    const addChild = (event) => {
      event.preventDefault();

      startLoading();
      fetch(API + "/mourned-people", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userID: this.state.userID,
          name: this.state.mournedName,
          gedenkseitenLink: this.state.gsLink,
        }),
      })
        .then((response) => response.json())
        .catch(stopLoading)
        .then((response) => {
          stopLoading();
          if (response.error) {
            alert(response.reason);
            return;
          }
          this.setState({ hasAddedChild: true });
        });
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  {!this.state.userID && !this.state.isSignupShown && (
                    <form onSubmit={checkEmail}>
                      <fieldset>
                        <div className="mb-12">
                          <Input
                            type="email"
                            label="Deine E-Mail Adresse"
                            placeholder="Deine E-Mail Adresse"
                            labelHidden
                            onChange={(e) =>
                              this.setState({ userEmail: e.target.value })
                            }
                            required
                          />
                          <div
                            className="text-xxs"
                            style={{ marginTop: 10, display: "flex" }}
                          >
                            <img
                              src={require("../assets/icons/info.svg")}
                              style={{ width: 20, height: 20 }}
                              alt="Info"
                              className="mr-8"
                            />
                            <span>
                              Gib hier die E-Mail Adresse an, mit der du dich
                              zur Gedenkfeier angemeldet hast.
                            </span>
                          </div>
                        </div>
                        <div className="mt-24 mb-32">
                          <Button
                            color="primary"
                            loading={this.state.isLoading}
                            wide
                          >
                            Weiter
                          </Button>
                        </div>
                      </fieldset>
                    </form>
                  )}
                  {this.state.userID && !this.state.hasAddedChild && (
                    <form onSubmit={addChild}>
                      <fieldset>
                        <div className="mb-12">
                          <Input
                            type="name"
                            label="Trag hier den Namen Deines Kindes ein"
                            onChange={(e) =>
                              this.setState({ mournedName: e.target.value })
                            }
                            required
                          />
                          <Input
                            type="text"
                            label="Verlink die Gedenkseite"
                            placeholder="https://www.gedenkseiten.de/..."
                            onChange={(e) =>
                              this.setState({ gsLink: e.target.value })
                            }
                          />
                        </div>
                        <div className="mt-24 mb-32">
                          <Button
                            color="primary"
                            loading={this.state.isLoading}
                            wide
                          >
                            Eintragen
                          </Button>
                        </div>
                      </fieldset>
                    </form>
                  )}
                  {this.state.isSignupShown && (
                    <form onSubmit={signupParticipateAddChild}>
                      <fieldset>
                        <div className="mb-12">
                          <div style={{ display: "flex" }}>
                            <div style={{ marginRight: 20 }}>
                              <Input
                                type="name"
                                label="Vorname"
                                onChange={(e) =>
                                  this.setState({
                                    userFirstName: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                            <div>
                              <Input
                                type="name"
                                label="Nachname"
                                onChange={(e) =>
                                  this.setState({
                                    userLastName: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                          </div>
                          <Input
                            type="email"
                            label="Deine E-Mail Adresse"
                            value={this.state.userEmail}
                            onChange={(e) =>
                              this.setState({ userEmail: e.target.value })
                            }
                            required
                          />
                        </div>
                        <div className="mt-24 mb-32">
                          <Button
                            color="primary"
                            loading={this.state.isLoading}
                            wide
                          >
                            Weiter
                          </Button>
                        </div>
                      </fieldset>
                    </form>
                  )}
                  {this.state.hasAddedChild && (
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.setState({ hasAddedChild: false });
                      }}
                    >
                      <fieldset>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          className="mt-50"
                        >
                          <img
                            src={require("../assets/icons/heart.svg")}
                            style={{ width: 40, height: 37 }}
                            alt="Heart"
                            className="mb-16 your-child__heart"
                          />
                          <span>In Gedenken an {this.state.mournedName}</span>
                          <p
                            className="mt-50"
                            style={{ fontSize: 16, textAlign: "center" }}
                          >
                            Wir werden den Kindern mit Ihren Namen in der
                            Gedenkfeier gedenken. Schön, dass du dabei bist!
                          </p>
                          <div className="mt-24">
                            <Button color="dark" loading={this.state.isLoading}>
                              Noch ein Kind eintragen
                            </Button>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

KGYourChild.propTypes = propTypes;
KGYourChild.defaultProps = defaultProps;

export default KGYourChild;
