import React from "react";
import GenericSection from "../components/sections/GenericSection";
import MetaTags from "../utils/MetaTags";

class Privacy extends React.Component {
  render() {
    return (
      <>
        <MetaTags title="Datenschutz" />

        <GenericSection>
          <div className="container-sm text-xs">
            <h1 className="mb-0">Datenschutzerklärung</h1>
            <h5>I. NAME UND ANSCHRIFT DES VERANTWORTLICHEN</h5>

            <p>
              <b>CONVELA GmbH</b>
              <br />
              Vertreten durch die Geschäftsführerin: Stefanie Oeft-Geffarth
              <br />
              <br />
              Blumenstraße 19
              <br />
              06108 Halle (Saale)
              <br />
              Deutschland
              <br />
              <br />
              Telefon: +49 (0) 345 77923807
              <br />
              e-mail: office@convela.de
              <br />
              www.convela.eu
              <br />
              <br />
              Steuernummer: 110/117/43422
              <br />
              UID: DE310741889
              <br />
              Amtsgericht Stendal HRB 24046
              <br />
            </p>

            <h5>II. NAME UND ANSCHRIFT DES DATENSCHUTZBEAUFTRAGTEN</h5>

            <p>
              Der Datenschutzbeauftragte des Verantwortlichen ist:
              <br />
              Dr. Renko Geffarth
              <br />
              <br />
              <b>CONVELA GmbH</b>
              <br />
              Blumenstraße 19
              <br />
              06108 Halle (Saale)
              <br />
              Deutschland
              <br />
              <br />
              Telefon: +49 (0) 345 77923807
              <br />
              E-Mail: rg@convela.de
              <br />
              www.convela.eu
            </p>

            <h5>III. NAME UND ANSCHRIFT DER ZUSTÄNDIGEN AUFSICHTSBEHÖRDE</h5>

            <p>
              Landesbeauftragter für den Datenschutz Sachsen-Anhalt
              <br />
              Herr Dr. Harald von Bose
              <br />
              Geschäftsstelle und Besucheradresse: Leiterstraße 9, 39104
              Magdeburg
              <br />
              Postadresse: Postfach 1947, 39009 Magdeburg
              <br />
              Telefon: +49 (0) 391 81803-0
              <br />
              Telefax: +49 (0) 391 81803-33
              <br />
              E-Mail: poststelle@lfd.sachsen-anhalt.de
            </p>

            <h5>IV. ALLGEMEINES ZUR DATENVERARBEITUNG</h5>

            <p>
              <b>1. Umfang der Verarbeitung personenbezogener Daten</b>
              <p>
                Wir verarbeiten personenbezogene Daten unserer Nutzer
                grundsätzlich nur, soweit dies zur Bereitstellung einer
                funktionsfähigen Website sowie unserer Inhalte und Leistungen
                erforderlich ist. Die Verarbeitung personenbezogener Daten
                unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des
                Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine
                vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
                nicht möglich ist und die Verarbeitung der Daten durch
                gesetzliche Vorschriften gestattet ist.
              </p>

              <b>
                2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
              </b>
              <p>
                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten
                eine Einwilligung der betroffenen Person einholen, dient Art. 6
                Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als
                Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen
                Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei
                die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1
                lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
                Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
                Maßnahmen erforderlich sind. Soweit eine Verarbeitung
                personenbezogener Daten zur Erfüllung einer rechtlichen
                Verpflichtung erforderlich ist, der unser Unternehmen
                unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
                Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen
                der betroffenen Person oder einer anderen natürlichen Person
                eine Verarbeitung personenbezogener Daten erforderlich machen,
                dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage. Ist die
                Verarbeitung zur Wahrung eines berechtigten Interesses unseres
                Unternehmens oder eines Dritten erforderlich und überwiegen die
                Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
                erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f
                DSGVO als Rechtsgrundlage für die Verarbeitung.
              </p>

              <b>3. Datenlöschung und Speicherdauer</b>
              <p>
                Die personenbezogenen Daten der betroffenen Person werden
                gelöscht oder gesperrt, sobald der Zweck der Speicherung
                entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn
                dies durch den europäischen oder nationalen Gesetzgeber in
                unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
                Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
                wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann,
                wenn eine durch die genannten Normen vorgeschriebene
                Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit
                zur weiteren Speicherung der Daten für einen Vertragsabschluss
                oder eine Vertragserfüllung besteht.
              </p>
            </p>

            <h5>V. BEREITSTELLUNG DER WEBSITE UND ERSTELLUNG VON LOGFILES</h5>

            <p>
              <b>1. Beschreibung und Umfang der Datenverarbeitung</b>
              <p>
                Bei jedem Aufruf unserer Internetseite erfasst unser System
                automatisiert Daten und Informationen vom Computersystem des
                aufrufenden Rechners. Folgende Daten werden hierbei erhoben:
                Informationen über den Browsertyp und die verwendete Version
                <br />
                Das Betriebssystem des Nutzers
                <br />
                Den Internet-Service-Provider des Nutzers
                <br />
                Die IP-Adresse des Nutzers
                <br />
                Datum und Uhrzeit des Zugriffs
                <br />
                Websites, von denen das System des Nutzers auf unsere
                Internetseite gelangt
                <br />
                Websites, die vom System des Nutzers über unsere Website
                aufgerufen werden.
                <br />
                Die Daten werden ebenfalls in den Logfiles unseres Systems
                gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
                personenbezogenen Daten des Nutzers findet nicht statt.
              </p>

              <b>2. Rechtsgrundlage für die Datenverarbeitung</b>
              <p>
                Rechtsgrundlage für die vorübergehende Speicherung der Daten und
                der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
              </p>

              <b>3. Zweck der Datenverarbeitung</b>
              <p>
                Die vorübergehende Speicherung der IP-Adresse durch das System
                ist notwendig, um eine Auslieferung der Website an den Rechner
                des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des
                Nutzers für die Dauer der Sitzung gespeichert bleiben.
                <br />
                Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit
                der Website sicherzustellen. Zudem dienen uns die Daten zur
                Optimierung der Website und zur Sicherstellung der Sicherheit
                unserer informationstechnischen Systeme. Eine Auswertung der
                Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
                statt.
                <br />
                In diesen Zwecken liegt auch unser berechtigtes Interesse an der
                Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
              </p>

              <b>4. Dauer der Speicherung</b>
              <p>
                Die Daten werden gelöscht, sobald sie für die Erreichung des
                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle
                der Erfassung der Daten zur Bereitstellung der Website ist dies
                der Fall, wenn die jeweilige Sitzung beendet ist.
                <br />
                Im Falle der Speicherung der Daten in Logfiles ist dies nach
                spätestens sieben Tagen der Fall. Eine darüberhinausgehende
                Speicherung ist möglich. In diesem Fall werden die IP-Adressen
                der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des
                aufrufenden Clients nicht mehr möglich ist.
              </p>

              <b>5. Widerspruchs- und Beseitigungsmöglichkeit</b>
              <p>
                Die Erfassung der Daten zur Bereitstellung der Website und die
                Speicherung der Daten in Logfiles ist für den Betrieb der
                Internetseite zwingend erforderlich. Es besteht folglich seitens
                des Nutzers keine Widerspruchsmöglichkeit.
              </p>
            </p>

            <h5>VI. VERWENDUNG VON COOKIES</h5>

            <p>
              <b>1. Beschreibung und Umfang der Datenverarbeitung</b>
              <p>
                Unsere Webseite verwendet Cookies ausschießlich im Zusammenhang
                mit dem Einsatz von Google Analytics (siehe unter IX. Webanalyse
                durch Google Analytics). Bei Cookies handelt es sich um
                Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf
                dem Computersystem des Nutzers gespeichert werden. Ruft ein
                Nutzer eine Website auf, so kann ein Cookie auf dem
                Betriebssystem des Nutzers gespeichert werden. Dieser Cookie
                enthält eine charakteristische Zeichenfolge, die eine eindeutige
                Identifizierung des Browsers beim erneuten Aufrufen der Website
                ermöglicht.
                <br />
                <br />
                Wir verwenden auf unserer Website Cookies, die eine Analyse des
                Surfverhaltens der Nutzer ermöglichen. Auf diese Weise können
                u.a. folgende Daten übermittelt werden: Eingegebene Suchbegriffe
                Häufigkeit von Seitenaufrufen Inanspruchnahme von
                Website-Funktionen
                <br />
                Rechtsgrundlage für die Übermittlung solche Analyse-Cookies ist
                Art. 6 Abs. 1 lit. f DSGVO. Beim Aufruf unserer Website wird der
                Nutzer über die Verwendung von Cookies zu Analysezwecken
                informiert und seine Einwilligung zur Verarbeitung der in diesem
                Zusammenhang verwendeten personenbezogenen Daten eingeholt. In
                diesem Zusammenhang erfolgt auch ein Hinweis auf diese
                Datenschutzerklärung.
              </p>

              <b>2. Rechtsgrundlage für die Datenverarbeitung</b>
              <p>
                Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                unter Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1
                lit. f DSGVO.
                <br />
                Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                unter Verwendung von Cookies zu Analysezwecken ist bei Vorliegen
                einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1
                lit. a DSGVO.
              </p>

              <b>3. Zweck der Datenverarbeitung</b>
              <p>
                Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die
                Qualität unserer Website und ihre Inhalte zu verbessern. Durch
                die Analyse-Cookies erfahren wir, wie die Website genutzt wird,
                und können so unser Angebot stetig optimieren. So analysieren
                wir u.a. die Häufigkeit des Aufrufs einzelner Seiten, die
                bevorzugte Spracheinstellung und die geografische Herkunft der
                Nutzer, die Dauer des Aufenthalts auf unserer Website und die
                verwendeten Browser und Endgeräte, um unser Angebot besser auf
                die Interessen und Bedürfnisse der Nutzer auszurichten. Eine
                vollständige Liste der Einsatzzwecke von Analysecookies erhalten
                Sie auf Anfrage von unserem Datenschutzbeauftragten.
                <br />
                In diesen Zwecken liegt auch unser berechtigtes Interesse in der
                Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit.
                f DSGVO.
              </p>

              <b>
                4. Dauer der Speicherung, Widerspruchs- und
                Beseitigungsmöglichkeit
              </b>
              <p>
                Cookies werden auf dem Rechner des Nutzers gespeichert und von
                diesem an unsere Seite übermittelt. Daher haben Sie als Nutzer
                auch die volle Kontrolle über die Verwendung von Cookies. Durch
                eine Änderung der Einstellungen in Ihrem Internetbrowser können
                Sie die Übertragung von Cookies deaktivieren oder einschränken.
                Bereits gespeicherte Cookies können jederzeit gelöscht werden.
                Dies kann auch automatisiert erfolgen. Werden Cookies für unsere
                Website deaktiviert, können möglicherweise nicht mehr alle
                Funktionen der Website vollumfänglich genutzt werden.
              </p>
            </p>

            <h5>VII. NEWSLETTER</h5>

            <p>
              <b>1. Beschreibung und Umfang der Datenverarbeitung</b>
              <p>
                Auf unserer Internetseite besteht die Möglichkeit, einen
                kostenfreien Newsletter zu abonnieren. Dabei werden bei der
                Anmeldung zum Newsletter die Daten aus der Eingabemaske an uns
                übermittelt.
                <br />
                Übermitteltes Datum ist die E-Mail-Adresse des Nutzers. Zudem
                werden folgende Daten bei der Anmeldung erhoben:
                <br />
                IP-Adresse des aufrufenden Rechners
                <br />
                Datum und Uhrzeit der Registrierung
                <br />
                <br />
                Für die Verarbeitung der Daten wird im Rahmen des
                Anmeldevorgangs Ihre Einwilligung eingeholt und auf diese
                Datenschutzerklärung verwiesen.
                <br />
                Es erfolgt im Zusammenhang mit der Datenverarbeitung für den
                Versand von Newslettern keine Weitergabe der Daten an Dritte.
                Die Daten werden ausschließlich für den Versand des Newsletters
                verwendet.
              </p>

              <b>2. Rechtsgrundlage für die Datenverarbeitung</b>
              <p>
                Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung
                zum Newsletters durch den Nutzer ist bei Vorliegen einer
                Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
              </p>

              <b>3. Zweck der Datenverarbeitung</b>
              <p>
                Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den
                Newsletter zuzustellen.
                <br />
                Die Erhebung sonstiger personenbezogener Daten im Rahmen des
                Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder
                der verwendeten E-Mail-Adresse zu verhindern.
              </p>

              <b>4. Dauer der Speicherung</b>
              <p>
                Die Daten werden gelöscht, sobald sie für die Erreichung des
                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die
                E-Mail-Adresse des Nutzers wird demnach solange gespeichert, wie
                das Abonnement des Newsletters aktiv ist.
                <br />
                Die sonstigen im Rahmen des Anmeldevorgangs erhobenen
                personenbezogenen Daten werden in der Regel nach einer Frist von
                sieben Tagen gelöscht.
              </p>

              <b>5. Widerspruchs- und Beseitigungsmöglichkeit</b>
              <p>
                Das Abonnement des Newsletters kann durch den betroffenen Nutzer
                jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem
                Newsletter ein entsprechender Link. Hierdurch wird ebenfalls ein
                Widerruf der Einwilligung der Speicherung der während des
                Anmeldevorgangs erhobenen personenbezogenen Daten ermöglicht.
              </p>
            </p>

            <h5>VIII. KONTAKTFORMULAR UND E-MAIL-KONTAKT</h5>

            <p>
              <b>1. Beschreibung und Umfang der Datenverarbeitung</b>
              <p>
                Auf unserer Internetseite ist ein Kontaktformular vorhanden,
                welches für die elektronische Kontaktaufnahme genutzt werden
                kann. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in
                der Eingabemaske eingegebenen Daten an uns übermittelt und
                gespeichert. Diese Daten sind:
                <br />
                Vorname, Name
                <br />
                E-Mail-Adresse
                <br />
                Betreff der Nachricht
                <br />
                Individuelle Nachricht
                <br />
                <br />
                Im Zeitpunkt der Absendung der Nachricht werden zudem folgende
                Daten gespeichert:
                <br />
                Die IP-Adresse des Nutzers
                <br />
                Datum und Uhrzeit der Registrier
                <br />
                ung
                <br />
                Für die Verarbeitung der Daten wird im Rahmen des
                Absendevorgangs Ihre Einwilligung eingeholt und auf diese
                Datenschutzerklärung verwiesen. Alternativ ist eine
                Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich.
                In diesem Fall werden die mit der E-Mail übermittelten
                personenbezogenen Daten des Nutzers gespeichert.
                <br />
                Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
                Dritte. Die Daten werden ausschließlich für die Verarbeitung der
                Konversation verwendet.
              </p>

              <b>2. Rechtsgrundlage für die Datenverarbeitung</b>
              <p>
                Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
                einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                <br />
                Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge
                einer Übersendung einer E-Mail übermittelt werden, ist Art. 6
                Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss
                eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
                Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
              </p>

              <b>3. Zweck der Datenverarbeitung</b>
              <p>
                Die Verarbeitung der personenbezogenen Daten aus der
                Eingabemaske dient uns allein zur Bearbeitung der
                Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt
                hieran auch das erforderliche berechtigte Interesse an der
                Verarbeitung der Daten.
                <br />
                Die sonstigen während des Absendevorgangs verarbeiteten
                personenbezogenen Daten dienen dazu, einen Missbrauch des
                Kontaktformulars zu verhindern und die Sicherheit unserer
                informationstechnischen Systeme sicherzustellen.
              </p>

              <b>4. Dauer der Speicherung</b>
              <p>
                Die Daten werden gelöscht, sobald sie für die Erreichung des
                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die
                personenbezogenen Daten aus der Eingabemaske des
                Kontaktformulars und diejenigen, die per E-Mail übersandt
                wurden, ist dies dann der Fall, wenn die jeweilige Konversation
                mit dem Nutzer beendet ist. Beendet ist die Konversation dann,
                wenn sich aus den Umständen entnehmen lässt, dass der betroffene
                Sachverhalt abschließend geklärt ist.
                <br />
                Die während des Absendevorgangs zusätzlich erhobenen
                personenbezogenen Daten werden spätestens nach einer Frist von
                sieben Tagen gelöscht.
              </p>

              <b>5. Widerspruchs- und Beseitigungsmöglichkeit</b>
              <p>
                Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
                Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt
                der Nutzer per E-Mail Kontakt mit uns auf, so kann er der
                Speicherung seiner personenbezogenen Daten jederzeit
                widersprechen. In einem solchen Fall kann die Konversation nicht
                fortgeführt werden.
                <br />
                Zur Löschung oder zur Änderung Ihrer Daten senden Sie bitte eine
                E-Mail an den in dieser Erklärung genannten
                Datenschutzbeauftragten. Alle personenbezogenen Daten, die im
                Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem
                Fall gelöscht.
              </p>
            </p>

            <h5>IX. DATENVERARBEITUNG DURCH DRITTANBIETER</h5>

            <p>
              <p>
                Diese Website setzt Dienste anderer Anbieter ein. Mit diesen
                Dienstleistern hat die Betreiberin dieser Website, die CONVELA
                GmbH, ggf. Verträge zur Auftragsdatenverarbeitung gemäß Art. 28
                DSGVO abgeschlossen. Nachfolgend sind die Dienstleister sowie
                die von diesen Dienstleistern im Auftrag der CONVELA GmbH
                verarbeiteten Daten mit den jeweiligen Gründen der
                Datenverarbeitung aufgeführt.
              </p>

              <b>1. Vimeo</b>
              <p>
                Unsere Website blendet Inhalte der Seite Vimeo ein. Betreiber
                der entsprechenden Plugins ist Vimeo LLC, 555 West 18th Street,
                New York, New York 10011, USA. Wenn Sie eine unserer Seiten mit
                Vimeo-Inhalten besuchen, stellt Ihr Browser eine direkte
                Verbindung zu den Servern von Vimeo her und ruft die Inhalte
                dort ab, um sie Ihnen anzuzeigen. Rechtsgrundlage hierfür ist
                Art. 6 Abs. 1 f) DSGVO (Interessenabwägung, basierend auf
                unserem Interesse, eine Website mit möglichst interessanten und
                ansprechenden Inhalten anzubieten).
                <br />
                <br />
                Bei der Einbindung von Vimeo-Inhalten kommuniziert Ihr Browser
                mit einem Server von Vimeo in den USA, der dabei den
                entsprechenden Abruf der Inhalte durch Ihren Browser erfassen
                kann. Es gibt aktuell keinen Beschluss der EU-Kommission, dass
                die USA allgemein ein angemessenes Datenschutzniveau bieten. Die
                personenbezogenen Daten werden auf der Grundlage von sogenannten
                Standardvertragsklauseln in einem zwischen den Datenübermittlern
                geschlossenen Vertrag an Vimeo übermittelt. Informationen zur
                Datenrichtlinie von Vimeo und weitere Informationen dazu, wie
                Vimeo personenbezogene Daten verarbeitet, einschließlich der
                Rechtsgrundlage, auf die Vimeo sich stützt, und der
                Möglichkeiten zur Wahrnehmung der Rechte betroffener Personen
                gegenüber Vimeo, sind in der Datenrichtlinie von Vimeo unter
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vimeo.com/privacy
                </a>{" "}
                zu finden.
                <br />
                <br />
                Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie
                Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen Profil
                zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
                Vimeo-Account ausloggen, bevor Sie unsere Website aufrufen.
              </p>

              <b>2. JotForm</b>
              <p>
                Unsere Website nutzt Formulare der Seite JotForm. Betreiber der
                entsprechenden Plugins ist JotForm Inc., 111 Pine St. Suite
                1815, San Francisco CA 94111, USA. Wenn Sie eine unserer Seiten
                mit einem JotForm-Formular besuchen, wird eine Verbindung
                zwischen Ihrem Browser und den Servern von JotForm aufgebaut und
                Sie können die verschiedenen Funktionalitäten von JotForm
                nutzen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO
                (Vertragserfüllung und vorvertragliche Maßnahmen).
                <br />
                <br />
                Bei der Nutzung (Ausfüllen, Absenden) des entsprechenden
                JotForm-Formulars werden Daten von Ihrem Browser an JotForm in
                den USA übertragen. Es gibt aktuell keinen Beschluss der
                EU-Kommission, dass die USA allgemein ein angemessenes
                Datenschutzniveau bieten. Die personenbezogenen Daten werden auf
                der Grundlage von sogenannten Standardvertragsklauseln in einem
                zwischen den Datenübermittlern geschlossenen Vertrag an Jotform
                übermittelt. Informationen zur Datenrichtlinie von Jotform und
                weitere Informationen dazu, wie Jotform personenbezogene Daten
                verarbeitet, einschließlich der Rechtsgrundlage, auf die Jotform
                sich stützt, und der Möglichkeiten zur Wahrnehmung der Rechte
                betroffener Personen gegenüber Jotform, sind in der
                Datenrichtlinie von Jotform unter
                <a
                  href="https://www.jotform.com/gdpr-compliance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.jotform.com/gdpr-compliance
                </a>{" "}
                zu finden.
                <br />
                Mehr erfahren Sie unter{" "}
                <a
                  href="https://www.jotform.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.jotform.com/privacy/
                </a>
                .
              </p>
            </p>

            <h5>X. RECHTE DER BETROFFENEN PERSON</h5>

            <p>
              <p>
                Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
                Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte
                gegenüber dem Verantwortlichen zu:
              </p>

              <b>1. Auskunftsrecht</b>
              <p>
                Sie können von dem Verantwortlichen eine Bestätigung darüber
                verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
                verarbeitet werden.
                <br />
                Liegt eine solche Verarbeitung vor, können Sie von dem
                Verantwortlichen über folgende Informationen Auskunft verlangen:
                <br />
                die Zwecke, zu denen die personenbezogenen Daten verarbeitet
                werden
                <br />
                die Kategorien von personenbezogenen Daten, welche verarbeitet
                werden
                <br />
                die Empfänger bzw. die Kategorien von Empfängern, gegenüber
                denen die Sie betreffenden personenbezogenen Daten offengelegt
                wurden oder noch offengelegt werden
                <br />
                die geplante Dauer der Speicherung der Sie betreffenden
                personenbezogenen Daten oder, falls konkrete Angaben hierzu
                nicht möglich sind, Kriterien für die Festlegung der
                Speicherdauer
                <br />
                das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                betreffenden personenbezogenen Daten, eines Rechts auf
                Einschränkung der Verarbeitung durch den Verantwortlichen oder
                eines Widerspruchsrechts gegen diese Verarbeitung
                <br />
                das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
                <br />
                alle verfügbaren Informationen über die Herkunft der Daten, wenn
                die personenbezogenen Daten nicht bei der betroffenen Person
                erhoben werden
                <br />
                das Bestehen einer automatisierten Entscheidungsfindung
                einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
                zumindest in diesen Fällen – aussagekräftige Informationen über
                die involvierte Logik sowie die Tragweite und die angestrebten
                Auswirkungen einer derartigen Verarbeitung für die betroffene
                Person.
                <br />
                <br />
                Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die
                Sie betreffenden personenbezogenen Daten in ein Drittland oder
                an eine internationale Organisation übermittelt werden. In
                diesem Zusammenhang können Sie verlangen, über die geeigneten
                Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
                Übermittlung unterrichtet zu werden.
              </p>

              <b>2. Recht auf Berichtigung</b>
              <p>
                Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
                gegenüber dem Verantwortlichen, sofern die verarbeiteten
                personenbezogenen Daten, die Sie betreffen, unrichtig oder
                unvollständig sind. Der Verantwortliche hat die Berichtigung
                unverzüglich vorzunehmen.
              </p>

              <b>3. Recht auf Einschränkung der Verarbeitung</b>
              <p>
                Unter den folgenden Voraussetzungen können Sie die Einschränkung
                der Verarbeitung der Sie betreffenden personenbezogenen Daten
                verlangen:
                <br />
                wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
                Daten für eine Dauer bestreiten, die es dem Verantwortlichen
                ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                überprüfen;
                <br />
                die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                personenbezogenen Daten ablehnen und stattdessen die
                Einschränkung der Nutzung der personenbezogenen Daten verlangen;
                <br />
                der Verantwortliche die personenbezogenen Daten für die Zwecke
                der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                benötigen, oder
                <br />
                wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
                DSGVO eingelegt haben und noch nicht feststeht, ob die
                berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
                überwiegen.
                <br />
                <br />
                Wurde die Verarbeitung der Sie betreffenden personenbezogenen
                Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung
                abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
                der Rechte einer anderen natürlichen oder juristischen Person
                oder aus Gründen eines wichtigen öffentlichen Interesses der
                Union oder eines Mitgliedstaats verarbeitet werden.
                <br />
                Wurde die Einschränkung der Verarbeitung nach den o.g.
                Voraussetzungen eingeschränkt, werden Sie von dem
                Verantwortlichen unterrichtet, bevor die Einschränkung
                aufgehoben wird.
              </p>

              <b>4. Recht auf Löschung</b>
              <b>(a) Löschungspflicht</b>
              <p>
                Sie können von dem Verantwortlichen verlangen, dass die Sie
                betreffenden personenbezogenen Daten unverzüglich gelöscht
                werden, und der Verantwortliche ist verpflichtet, diese Daten
                unverzüglich zu löschen, sofern einer der folgenden Gründe
                zutrifft: Die Sie betreffenden personenbezogenen Daten sind für
                die Zwecke, für die sie erhoben oder auf sonstige Weise
                verarbeitet wurden, nicht mehr notwendig.
                <br />
                Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
                gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO
                stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für
                die Verarbeitung.
                <br />
                Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
                Verarbeitung ein und es liegen keine vorrangigen berechtigten
                Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21
                Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                <br />
                Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                verarbeitet.
                <br />
                Die Löschung der Sie betreffenden personenbezogenen Daten ist
                zur Erfüllung einer rechtlichen Verpflichtung nach dem
                Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
                der Verantwortliche unterliegt.
                <br />
                Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
                angebotene Dienste der Informationsgesellschaft gemäß Art. 8
                Abs. 1 DSGVO erhoben.
              </p>

              <b>(b) Information an Dritte</b>
              <p>
                Hat der Verantwortliche die Sie betreffenden personenbezogenen
                Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu
                deren Löschung verpflichtet, so trifft er unter Berücksichtigung
                der verfügbaren Technologie und der Implementierungskosten
                angemessene Maßnahmen, auch technischer Art, um für die
                Datenverarbeitung Verantwortliche, die die personenbezogenen
                Daten verarbeiten, darüber zu informieren, dass Sie als
                betroffene Person von ihnen die Löschung aller Links zu diesen
                personenbezogenen Daten oder von Kopien oder Replikationen
                dieser personenbezogenen Daten verlangt haben.
              </p>

              <b>(c) Ausnahmen</b>
              <p>
                Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                erforderlich ist
                <br />
                zur Ausübung des Rechts auf freie Meinungsäußerung und
                Information;
                <br />
                zur Erfüllung einer rechtlichen Verpflichtung, die die
                Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
                dem der Verantwortliche unterliegt, erfordert, oder zur
                Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
                oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde;
                <br />
                aus Gründen des öffentlichen Interesses im Bereich der
                öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
                Art. 9 Abs. 3 DSGVO;
                <br />
                für im öffentlichen Interesse liegende Archivzwecke,
                wissenschaftliche oder historische Forschungszwecke oder für
                statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
                Abschnitt a) genannte Recht voraussichtlich die Verwirklichung
                der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
                beeinträchtigt, oder zur Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen.
              </p>

              <b>5. Recht auf Unterrichtung</b>
              <p>
                Haben Sie das Recht auf Berichtigung, Löschung oder
                Einschränkung der Verarbeitung gegenüber dem Verantwortlichen
                geltend gemacht, ist dieser verpflichtet, allen Empfängern,
                denen die Sie betreffenden personenbezogenen Daten offengelegt
                wurden, diese Berichtigung oder Löschung der Daten oder
                Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies
                erweist sich als unmöglich oder ist mit einem
                unverhältnismäßigen Aufwand verbunden.
                <br />
                Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über
                diese Empfänger unterrichtet zu werden.
              </p>

              <b>6. Recht auf Datenübertragbarkeit</b>
              <p>
                Sie haben das Recht, die Sie betreffenden personenbezogenen
                Daten, die Sie dem Verantwortlichen bereitgestellt haben, in
                einem strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten. Außerdem haben Sie das Recht diese Daten einem anderen
                Verantwortlichen ohne Behinderung durch den Verantwortlichen,
                dem die personenbezogenen Daten bereitgestellt wurden, zu
                übermitteln, sofern die Verarbeitung auf einer Einwilligung gem.
                Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder
                auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und die
                Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                <br />
                In Ausübung dieses Rechts haben Sie ferner das Recht, zu
                erwirken, dass die Sie betreffenden personenbezogenen Daten
                direkt von einem Verantwortlichen einem anderen Verantwortlichen
                übermittelt werden, soweit dies technisch machbar ist.
                Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
                beeinträchtigt werden.
                <br />
                Das Recht auf Datenübertragbarkeit gilt nicht für eine
                Verarbeitung personenbezogener Daten, die für die Wahrnehmung
                einer Aufgabe erforderlich ist, die im öffentlichen Interesse
                liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde.
              </p>

              <b>7. Widerspruchsrecht</b>
              <p>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                betreffenden personenbezogenen Daten, die aufgrund von Art. 6
                Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
                gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                <br />
                Der Verantwortliche verarbeitet die Sie betreffenden
                personenbezogenen Daten nicht mehr, es sei denn, er kann
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
                die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
                Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen.
                <br />
                Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
                um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
                Widerspruch gegen die Verarbeitung der Sie betreffenden
                personenbezogenen Daten zum Zwecke derartiger Werbung
                einzulegen; dies gilt auch für das Profiling, soweit es mit
                solcher Direktwerbung in Verbindung steht.
                <br />
                Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
                so werden die Sie betreffenden personenbezogenen Daten nicht
                mehr für diese Zwecke verarbeitet.
                <br />
                Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
                Diensten der Informationsgesellschaft – ungeachtet der
                Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
                automatisierter Verfahren auszuüben, bei denen technische
                Spezifikationen verwendet werden.
              </p>

              <b>
                8. Recht auf Widerruf der datenschutzrechtlichen
                Einwilligungserklärung
              </b>
              <p>
                Sie haben das Recht, Ihre datenschutzrechtliche
                Einwilligungserklärung jederzeit zu widerrufen. Durch den
                Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund
                der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
                berührt.
              </p>

              <b>
                9. Automatisierte Entscheidung im Einzelfall einschließlich
                Profiling
              </b>
              <p>
                Sie haben das Recht, nicht einer ausschließlich auf einer
                automatisierten Verarbeitung – einschließlich Profiling –
                beruhenden Entscheidung unterworfen zu werden, die Ihnen
                gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher
                Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die
                Entscheidung
                <br />
                für den Abschluss oder die Erfüllung eines Vertrags zwischen
                Ihnen und dem Verantwortlichen erforderlich ist,
                <br />
                aufgrund von Rechtsvorschriften der Union oder der
                Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
                ist und diese Rechtsvorschriften angemessene Maßnahmen zur
                Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten
                Interessen enthalten oder
                <br />
                mit Ihrer ausdrücklichen Einwilligung erfolgt.
                <br />
                Allerdings dürfen diese Entscheidungen nicht auf besonderen
                Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO
                beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und
                angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie
                Ihrer berechtigten Interessen getroffen wurden.
                <br />
                Hinsichtlich der in 1. und 3. genannten Fälle trifft der
                Verantwortliche angemessene Maßnahmen, um die Rechte und
                Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu
                mindestens das Recht auf Erwirkung des Eingreifens einer Person
                seitens des Verantwortlichen, auf Darlegung des eigenen
                Standpunkts und auf Anfechtung der Entscheidung gehört.
              </p>

              <b>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</b>
              <p>
                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde
                bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
                ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
                Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                die DSGVO verstößt.
                <br />
                <br />
                Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
                unterrichtet den Beschwerdeführer über den Stand und die
                Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
                gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
              </p>
            </p>
          </div>
        </GenericSection>

        <div className="spacer-64 spacer-24-mobile has-bg-color"></div>
        <div className="spacer-24 has-bg-color"></div>
      </>
    );
  }
}

export default Privacy;
