import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Button from "../elements/Button";
import Input from "../elements/Input";
import { API } from "../../constants";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  state = {
    email: "",
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "cta section center-content-mobile",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );

    const handleSubmit = (event) => {
      event.preventDefault();

      const email = this.state.email;
      this.setState({ isLoading: true });

      fetch(API + "/user/subscribe-to-newsletter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          hasConsented: true,
        }),
      })
        .then((response) => response.json())
        .catch(() => this.setState({ isLoading: false }))
        .then((response) => {
          this.setState({ isLoading: false });
          if (response && response.error) {
            alert(response.reason);
          } else {
            alert("Vielen Dank. Sie sind jetzt im Newsletter angemeldet.");
            this.setState({ email: "" });
          }
        });
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-slogan">
              <h3 className="m-0">Sie möchten informiert bleiben?</h3>
            </div>
            <div className="cta-action">
              <form onSubmit={handleSubmit}>
                <Input
                  type="email"
                  placeholder="Ihre E-Mail Adresse"
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  formGroup="desktop"
                  labelHidden
                  style={{
                    background: "transparent",
                    cornerRadius: 2,
                    borderColor: "#91D4F8",
                    color: "white",
                  }}
                >
                  <Button
                    color="secondary"
                    type="submit"
                    loading={this.state.isLoading}
                  >
                    zum Newsletter anmelden
                  </Button>
                </Input>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
