const concatUrls = (...urls) => {
  const repeatingSlashesRegex = /([^:]|^)\/{2,}/g;

  return urls
    .filter(Boolean)
    .join('/')
    .replace(repeatingSlashesRegex, '$1/');
};

export default concatUrls;
