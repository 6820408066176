import { paramCase } from 'change-case';
import trim from 'lodash/trim';
import latinize from 'latinize';
import extend from 'lodash/extend';

extend(latinize.characters, {
  Ä: 'AE',
  Ö: 'OE',
  Ü: 'UE',
  ä: 'ae',
  ö: 'oe',
  ü: 'ue'
});

const stringToUrlFriendly = string => {
  if (!trim(string)) return;

  const normalizedString = latinize(string).toLowerCase();

  return paramCase(normalizedString);
};

export default stringToUrlFriendly;
