import React from "react";
import GenericSection from "../components/sections/GenericSection";
import MetaTags from "../utils/MetaTags";

class Terms extends React.Component {
  render() {
    return (
      <>
        <MetaTags title="Allgemeine Geschäftsbedingungen" />

        <GenericSection>
          <div className="container-sm text-xs">
            <h1 className="mb-0">AGB</h1>
            <h4 className="mt-12">
              Allgemeine Geschäftsbedingungen (AGB) für Trauerfeier.online
            </h4>
            <p>
              Die Website www.trauerfeier.online bietet Informationen über
              Online-Gedenkfeiern und Online-Trauerfeiern. Außerdem vermittelt
              sie zwischen Moderatoren und Rednern (Auftragnehmer) und
              Privatkunden (Auftraggeber). Für Information und Vermittlung sind
              keine AGB erforderlich.
            </p>
            <p>
              Für Verträge, die auf der Website www.trauerfeier.online
              vermittelt und zwischen Auftraggebern und Auftragnehmern
              geschlossen werden, gelten die{" "}
              <a
                href="https://app.funeral-market.place/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                AGB der Plattform Funeral Marketplace
              </a>
              .
            </p>

            <h5>1. Geltungsbereich</h5>
            <p>
              Die vorliegenden Geschäftsbedingungen („AGB“) gelten für das
              Nutzungsverhältnis zwischen dem Websitebetreiber und den Nutzern,
              das durch die Nutzung der Angebote auf der Website geschlossen
              wird. Diese Geschäftsbedingungen gelten ausschließlich.
              Geschäftsbedingungen, die hiervon abweichen, erkennt der
              Websitebetreiber – vorbehaltlich einer ausdrücklichen Zustimmung –
              nicht an.
              <br />
              <br />
              <b>
                Im Geltungsbereich dieser AGB gelten folgende
                Begriffsbestimmungen:
              </b>
              <ul>
                <li>„Websitebetreiber“ ist die CONVELA GmbH</li>
                <li>„Website“ ist www.trauerfeier.online</li>
                <li>
                  „Nutzer“ sind Moderatoren, Redner und Privatkunden („Kunden“).
                </li>
                <li>„Auftragnehmer“ sind Moderatoren und Redner.</li>
                <li>„Auftraggeber“ sind Privatkunden („Kunden“).</li>
              </ul>
            </p>

            <h5>2. Leistungsumfang der Website</h5>
            <p>
              Die Website bietet Kunden die Möglichkeit, sich über
              Vertragsleistungen von Moderatoren und Rednern zu informieren.
              Kunden haben insbesondere die Möglichkeit, Moderatoren und Redner
              mit Dienstleistungen zu beauftragen.
              <br />
              Der Websitebetreiber wird zu keinem Zeitpunkt Vertragspartner der
              zwischen Auftraggebern und Auftragnehmern geschlossenen Verträge.
              Diese Verträge kommen ausschließlich zwischen dem jeweiligen
              Auftraggeber und Auftragnehmer zustande.
              <br />
              Die Verfügbarkeit der Website liegt bei 98,5 % im Jahresmittel.
              Hiervon ausgenommen sind notwendige reguläre Wartungsarbeiten
              sowie diejenigen Zeiträume, in denen die Verfügbarkeit aufgrund
              von Ereignissen eingeschränkt wird, die der Websitebetreiber nicht
              zu vertreten hat (z.B. höhere Gewalt, Handlungen Dritter,
              technische Probleme oder Änderungen der Rechtslage).
            </p>

            <h5>3. Vertragsschluss</h5>
            <p>
              Für Verträge, die auf der Website www.trauerfeier.online
              vermittelt und zwischen Auftraggebern und Auftragnehmern
              geschlossen werden, gelten die{" "}
              <a
                href="https://app.funeral-market.place/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                AGB der Plattform Funeral Marketplace
              </a>
              .<br />
              <br />
              Abweichend von und ergänzend zu den Bestimmungen in den AGB der
              Plattform Funeral Marketplace gelten folgende Bestimmungen:
            </p>

            <h5>4. Rücktritt vom Vertrag</h5>
            <p>
              Ein Rücktritt vom Vertrag kann nur in schriftlicher Form (per Post
              oder E-Mail) erfolgen. Der Websitebetreiber ist hieran in keiner
              Weise beteiligt.
              <br />
              <br />
              Rücktritt durch den Auftraggeber / Kunden
              <br />
              Ein kostenfreier Rücktritt des Kunden vom geschlossenen Vertrag
              ist ohne Angabe von Gründen nach Abschluss des Vertrages nur vor
              Beginn der ersten kostenpflichtigen Dienstleistung (etwa dem
              Vorbereitungsgespräch) möglich. Bei einem späteren Rücktritt
              verbleibt die geleistete Anzahlung beim Auftragnehmer und die
              Restforderung folgt umgehend.
              <br />
              Eine Verschiebung des gebuchten Termins ist der Stornierung und
              Neubuchung gleichzusetzen, da hier ein neuer Vertrag geschlossen
              wird.
              <br />
              <br />
              Rücktritt durch den Auftragnehmer
              <br />
              Der Auftragnehmer behält sich vor, ebenfalls nach Abschluss des
              Vertrags und vor Beginn der ersten kostenpflichtigen
              Dienstleistung den Vertrag ohne Angabe von Gründen einseitig
              schriftlich per Post oder E-Mail aufzukündigen. Die geleistete
              Anzahlung wird in diesem Fall dem Auftraggeber zurückerstattet.
              <br />
              Sollten die vereinbarten Zahlungen durch den Auftraggeber nicht,
              unvollständig oder nicht fristgerecht beim Auftragnehmer eingehen,
              kann der Auftragnehmer ebenfalls vom Vertrag zurücktreten. Die bis
              dahin geleisteten Zahlungen werden nicht zurückerstattet.
              <br />
              Kann der Auftragnehmer in Folge von Krankheit, Unfall, Tod oder
              anderen wichtigen Gründen (z.B. Todesfall in der Familie, höhere
              Gewalt, etc.) die Vertragsleistung nicht erbringen, entfallen alle
              Ansprüche aus diesem Vertrag und bereits gezahltes Honorar wird
              umgehend zurückerstattet, sofern der Auftragnehmer keine/n vom
              Auftraggeber akzeptierte/n Ersatzrednerin / Ersatzredner stellen
              kann. Wird bei Ausfall des Auftragnehmers nur das fertig gestellte
              Redemanuskript gewünscht, um es z.B. durch einen selbst zu
              besorgenden Redner vortragen zu lassen, werden hierfür 30 % des
              vertraglich vereinbarten Honorars (ohne Spesen) berechnet.
              <br />
              Wenn der Auftragnehmer eine Ersatzrednerin / einen Ersatzredner
              stellen kann, der vom Auftraggeber akzeptiert wird, und diesem
              eine bereits fertig gestellte Rede zur Verfügung stellt, behalten
              alle vertraglichen Regelungen ihre Gültigkeit und der
              Auftragnehmer wird eine entsprechende Vergütung der Ersatzrednerin
              / des Ersatzredners weiterleiten.
              <br />
              Der Auftragnehmer wird immer versuchen, eine Ersatzrednerin /
              einen Ersatzredner zu stellen, jedoch ohne Garantie und
              Anerkennung einer Rechtspflicht.
            </p>

            <h5>5. Ton- und Bildrechte, Einwilligung</h5>
            <p>
              Während der Online-Gedenk- und /oder Trauerfeier werden Ton-,
              Foto- und/oder Filmaufnahmen angefertigt, u.a. durch eine vorher
              vereinbarte Aufzeichnung der Zeremonie. Der Auftraggeber behält
              alle Rechte an den entstandenen Aufnahmen und räumt dem
              Auftragnehmer, ebenso dem Websitebetreiber, keinerlei Rechte zur
              Nutzung oder Verwertung ein.
              <br />
              Der Auftraggeber stellt sicher, dass alle Teilnehmenden der
              Zeremonie über die Anfertigung von Ton-, Foto- und/oder
              Filmaufnahmen unterrichtet werden. Der Auftraggeber holt die
              Einwilligung der Teilnehmenden zur Anfertigung von Ton-, Foto-
              und/oder Filmaufnahmen rechtzeitig vor Beginn der Zeremonie ein.
              Der Auftragnehmer kann den Auftraggeber beim Einholen der
              Einwilligung unterstützen, z.B. durch Einschluss einer
              entsprechenden Information in der E-Mail, mit der die
              Teilnehmenden zur Zeremonie eingeladen werden.
            </p>

            <h5>6. Urheberrecht</h5>
            <p>
              Für den Inhalt der Zeremonie liegt das Urheberrecht (nicht:
              Nutzungsrecht) bei CONVELA GmbH und Omilia, bzw. dem Moderator
              und/oder Redner.
            </p>
          </div>
        </GenericSection>

        <div className="spacer-64 spacer-24-mobile has-bg-color"></div>
        <div className="spacer-24 has-bg-color"></div>
      </>
    );
  }
}

export default Terms;
